.reviews {
  .swiper {
    @media (width < $media-768) {
      width: calc(100% + var(--container-gap) + var(--container-gap));
      padding-right: var(--container-gap);
      padding-left: var(--container-gap);
      margin-left: calc(-1 * var(--container-gap));
    }
  }

  .swiper-slide {
    height: auto;
  }
}
