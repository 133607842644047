.reasons-card {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 40px 0 40px ;
  align-items: center;
  border-bottom: 1px solid #1A69AD;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  &:nth-child(4) {
    border-bottom: none
  }
  &:nth-child(5) {
    border-bottom: none
  }
}
@media (width < $media-768) {
  .reasons-card {
    padding: 24px 0;

    &__title {
      font-size: 14px;
      line-height: 18px;
    }

    &:nth-child(4) {
      border-bottom: 1px solid #1A69AD;
    }
  }

}
