@use 'sass:math';

.banner {
  &__inner {
    @include shadow;

    position: relative;
    width: 100%;
    padding: 102px 32px;
    overflow: hidden;
    border-radius: 8px;

    @media (width < $media-first) {
      padding: 32px 24px;
    }

    @media (width < $media-768) {
      padding: 24px;
    }
  }

  &__body {
    position: relative;
    z-index: 12;
    width: 575px;

    @media (width < $media-first) {
      width: 448px;
    }

    @media (width < $media-1024) {
      width: 398px;
    }

    @media (width < $media-768) {
      width: 100%;
    }
  }

  &__title {
    @include heading-h2;

    margin-bottom: 16px;
    color: $blue;

    @media (width < $media-first) {
      @include heading-h3;
    }

    @media (width < $media-768) {
      margin-bottom: 8px;
    }
  }

  &__text {
    @include body-big;

    margin-bottom: 64px;

    @media (width < $media-first) {
      margin-bottom: 48px;
    }

    @media (width < $media-1024) {
      @include body;

      margin-bottom: 32px;
    }
  }

  &__right {
    position: absolute;
    top: 0;
    right: 0;
    width: math.div(648px, 1276px) * 100%;
    height: 100%;

    @media (width < $media-1024) {
      width: 45%;
    }

    @media (width < $media-768) {
      display: none;
    }
  }

  &__pct {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
