@use 'sass:math';

.usp {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    grid-auto-rows: 1fr;

    @media (width < $media-1024) {
      grid-template-columns: 1fr;
    }
  }
}
