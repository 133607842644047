.reasons {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
  }
  .btn {
    height: 48px;
    margin-top: 18px;
  }
}
@media (width < $media-768) {
  .reasons {
    &__grid {
      grid-template-columns: 1fr;
      gap: 0;

    }
    .btn {
      margin: 8px auto 0;
    }
  }
}
