@mixin reset-link {
  font-family: inherit;
  color: inherit;
  text-decoration: none;
}

@mixin reset-input {
  font-family: inherit;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}

@mixin reset-button {
  width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
}
