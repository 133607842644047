.stars {
  display: flex;
  gap: 12px;

  &__star {
    width: 22px;
    height: 21px;
    color: $blue;
    fill: transparent;

    &--fill {
      fill: $blue;
    }
  }
}
