* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Mulish', sans-serif;
  color: $black;
  user-select: none;
}

img,
svg,
a {
  display: block;
}

a {
  @include reset-link;
}

button {
  @include reset-button;
}

input {
  @include reset-input;
}

b {
  font-weight: bold;
}

// stylelint-disable-next-line
img-comparison-slider {
  outline: none;
}
