@import 'typography';
@import 'shadow';
@import 'reset';

@mixin pseudo {
  display: block;
  content: '';
}

@mixin position-fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin crop-line {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin crop-height ($line) {
  -webkit-line-clamp: $line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
