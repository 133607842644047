@use 'sass:math';

.about {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // align-items: center;
    // justify-content: space-between;
    gap: 20px;

    @media (width < $media-1024) {
      align-items: unset;
    }

    @media (width < $media-768) {
      flex-direction: column-reverse;
      gap: 0;
    }
  }

  &__left {
    // width: math.div(1150px, 2);
    min-width: 0;
    max-width: 636px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: flex-end;


    @media (width < $media-768) {
      margin-top: 32px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &-text__box {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    justify-content: flex-end;
  }
  &__text {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  &__asset {
    width: 38px;
    height: 38px;
  }

  &__main {
    width: 100%;

    @media (width < $media-1024) {
      position: sticky;
      top: 40px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    // width: 628px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (width < $media-768) {
      width: 100%;
    }
  }

  &__title {
    @include heading-h3;

    display: block;
    margin-bottom: 12px;

    @media (width < $media-768) {
      @include body-big-bold;
    }
  }

  &__sub-title {
    @include body-big;

    // margin-bottom: 16px;
    color: $blue;

    @media (width < $media-768) {
      @include body;
    }
  }

  &__desc {
    @include body;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__number {
      font-weight: 600;
      font-size: 57px;
      line-height: 76px;
      color: #0968AD;
    }
    &__desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #222222;
    }
  }
  &-right__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  &__blue {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1A69AD;
    max-width: 300px;
  }
  &__blues {
    margin-top: 16px;
  }
  // &__wrap {
  //   margin-bottom: 32px;
  // }
}
@media (width < $media-1024) {
  .about {
    &__inner {
      grid-template-columns: 1fr;
      gap: 40px;
    }
    &__left {
      max-width: unset;
      align-items: unset;
    }
    &-text__box {
      justify-content: unset;
    }
  }
}

@media (width < $media-768) {
  .about {
    &__left {
      gap: 11px;
    }
    &-text__box {
      gap: 0;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
    &__asset {
      width: 24px;
      height: 24px;
    }
    &-item {
      gap: 8px;
      &__number {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
      }
    }
    &__right {
      gap: 32px;
    }
    &__flex {
      gap: 10px;
    }
    &-right__text {
      font-size: 14px;
      line-height: 18px;
    }
    &__blues {
      flex-direction: column;
      justify-content: unset;
      gap: 24px;
    }
    &__blue {
      max-width: unset;
    }
  }
}
