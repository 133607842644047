.reviews-slide {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 56px 32px 32px 32px;
  border: 1px solid $blue-light;
  border-radius: 8px;

  @media (width < $media-first) {
    padding: 56px 24px 24px 24px;
  }

  @media (width < $media-768) {
    padding: 64px 15px 24px 15px;
  }

  &__title {
    @include heading-h3;

    margin-bottom: 16px;

    @media (width < $media-768) {
      @include body-big-bold;

      margin-bottom: 8px;
    }
  }

  &__desc {
    @include body-big;

    margin-bottom: 24px;

    @media (width < $media-first) {
      margin-bottom: 16px;
    }

    @media (width < $media-768) {
      @include body;
    }
  }

  &__stars {
    margin-top: auto;
  }

  &__quote {
    position: absolute;
    top: 0;
    right: 32px;
  }
}
