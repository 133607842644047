.usp-card {
  --size-icon: 132px;
  --mb-to-button: 32px;

  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;

  @media (width < $media-first) {
    --size-icon: 95px;
  }

  &__icon {
    position: relative;
    z-index: 12;
    width: var(--size-icon);
    height: var(--size-icon);
    margin-right: auto;
    margin-left: auto;
  }

  &__desc {
    @include body;

    margin-bottom: var(--mb-to-button);
    color: $gray-dark;
  }

  &__body {
    @include shadow;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 94px 32px 32px 32px;
    margin-top: calc(var(--size-icon) / 2 * -1);
    border-radius: 8px;

    @media (width < $media-first) {
      padding: 72px 24px 24px 24px;
    }
  }

  &__title {
    @include heading-h3;

    margin-bottom: 16px;
    color: $blue;

    @media (width < $media-first) {
      @include body-big-bold;
    }
  }

  &__btn {
    margin-top: auto;

    @media (width < $media-first) {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__ul {
    margin-bottom: var(--mb-to-button);
  }

  &__li {
    @include body;

    display: flex;
    align-items: baseline;
    color: $gray-dark;

    &::before {
      @include pseudo;

      flex-shrink: 0;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      background-color: $blue;
      border-radius: 50%;
      transform: translateY(-2px);
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
