.modal-parent {
  @include position-fill;

  position: fixed;
  z-index: 400;
  display: flex;
  padding: 20px 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: none;
  opacity: 0;
  transition: $transition-duration opacity $transition-timing-function;

  [data-modal-body] {
    transition: $transition-duration transform $transition-timing-function;
    transform: translateY(10px);
  }

  [data-modal-menu] {
    transition: $transition-duration transform $transition-timing-function;
    transform: translateX(20px);
  }

  &--open {
    pointer-events: unset;
    opacity: 1;

    [data-modal-body] {
      transform: translateY(0);
    }

    [data-modal-menu] {
      transform: translateX(0);
    }
  }
}
