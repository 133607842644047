.faq {
  &__grid {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    gap: 24px;

    @media (width < $media-768) {
      gap: 20px;
    }
  }
}
