.header {
  position: relative;
  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 84px;

    @media (width < $media-1024) {
      height: 60px;
    }
  }

  &__main {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    @media (width < $media-800) {
      left: 0;
      width: 100%;
    }
  }
  &__second {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    z-index: 1;
    @media (width < $media-1024) {
      display: none;
    }
  }
  &__logo {
    padding: 4px;
    margin: -4px;
  }

  &__logo-img {
    @media (width < $media-1024) {
      width: 128px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 0 52px;

    @media (width < 1300px) {
      display: none;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 0 32px;
  }
  &__phone {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $black;
  }
  &__link {
    @include body-big;

    padding: 8px;
    margin: -8px;
    cursor: pointer;
    transition: $transition-duration color $transition-timing-function;

    &:hover {
      opacity: .7;
    }
    &-mobile {
      display: none;
    }
  }
  &-left {
    gap: 36px;
    display: flex;
    flex-direction: row;
    &__links {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px

    }
  }
  &__icon {
    width: 32px;
    height: 32px;
  }
  &__burger {
    padding: 4px;
    margin: -4px;
    cursor: pointer;
    transition: $transition-duration color $transition-timing-function;

    @media (width > 1300px) {
      display: none;
    }

    &:hover {
      color: $blue;
    }
    &--second {
      display: none;
    }
  }

  &__burger-icon {
    width: 30px;
    height: 22px;

    @media (width < $media-1024) {
      width: 25px;
      height: 17px;
    }
  }
}
@media (width < $media-768) {
  .header {
    &__phone {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: $white;
    }
    &-left {
      .btn {
        display: none;
      }
    }
    &__icon {
      width: 24px;
      height: 24px;
    }
    &__link {
      display: none;
    }
    &__link-mobile {
      display: block;
    }
  }
}

