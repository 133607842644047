.modal-body-info {
  &__btn {
    margin-top: 40px;
  }

  &__desc {
    text-align: center;
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
  }

  &__img {
    max-width: 100%;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
  }
}
