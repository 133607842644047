.overlay {
  @include position-fill;

  position: fixed;
  z-index: 199;
  pointer-events: none;
  background-color: rgba($blue-black, 0.6);
  opacity: 0;
  transition: $transition-duration opacity $transition-timing-function;

  &--show {
    pointer-events: unset;
    opacity: 1;
  }
}
