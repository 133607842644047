.awards {
  .swiper {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;

    // @media (width >= $media-768) {
    //   filter: drop-shadow(18.4133px 19.3824px 10.6603px rgba(0, 0, 0, 0.01)) drop-shadow(10.6603px 10.6603px 8.72208px rgba(0, 0, 0, 0.02)) drop-shadow(4.8456px 4.8456px 6.78384px rgba(0, 0, 0, 0.04)) drop-shadow(0.96912px 0.96912px 3.87648px rgba(0, 0, 0, 0.04)) drop-shadow(0 0 0 rgba(0, 0, 0, 0.04));
    // }

    @media (width < $media-768) {
      width: calc(100% + var(--container-gap) + var(--container-gap));
      max-width: unset;
      padding-right: var(--container-gap);
      padding-left: var(--container-gap);
      margin-left: calc(-1 * var(--container-gap));
    }
  }
}
