.rec-card {
  @include shadow;

  display: flex;
  align-items: center;
  padding: 32px;
  cursor: pointer;
  border-radius: 8px;
  transition:
    $transition-duration transform $transition-timing-function,
    $transition-duration background $transition-timing-function,
    $transition-duration color $transition-timing-function;
  gap: 0 20px;

  @media (width < $media-1024) {
    padding: 24px;
  }

  &:hover {
    color: $blue;
    background-color: $blue-light;
    transform: scale(0.95);
  }

  &__title {
    @include heading-h3;

    @media (width < $media-768) {
      @include body-bold;
    }
  }
}
