.social {
  $self: &;

  display: flex;
  gap: 0 16px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: $blue;
    background-color: $white;
    border-radius: 50%;
    transition: $transition-duration background-color $transition-timing-function;

    &:hover {
      background-color: $blue-light;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}
