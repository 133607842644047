.footer-main {
  & &__container {
    display: flex;

    @media (width < $media-1024) {
      flex-direction: column-reverse;
    }
  }

  &__col {
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 40px;

    @media (width < $media-first) {
      padding: 24px;
    }

    @media (width < $media-1024) {
      padding: 24px 0;
    }

    &--left {
      padding-left: 0;

      &::before {
        @include pseudo;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 50vw;
        background-color: $blue;

        @media (width < $media-1024) {
          right: unset;
          left: 50%;
          width: 150vw;
          transform: translateX(-50%);
        }
      }
    }

    &--right {
      padding-right: 0;
    }
  }

  &__title {
    @include heading-h3;

    @media (width < $media-768) {
      @include body-big-bold;
    }

    &--left {
      margin-bottom: 22px;
      color: $white;
    }

    &--right {
      margin-bottom: 8px;
      color: $black;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;
  }

  &__link {
    display: flex;
    align-items: baseline;
    width: max-content;
    max-width: 100%;
    padding: 4px;
    margin: -4px;
    color: $white;
    gap: 0 8px;
  }

  &__link-iocn {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    transform: translateY(5px);

    @media (width < $media-768) {
      width: 20px;
      height: 20px;
    }
  }

  &__link-text {
    @include body-big;

    @media (width < $media-768) {
      @include body;
    }
  }

  &__social {
    margin-top: 40px;
  }

  &__desc {
    @include body-big;

    margin-bottom: 32px;

    @media (width < $media-first) {
      margin-bottom: 24px;
    }

    @media (width < $media-768) {
      @include body;

      margin-bottom: 16px;
    }
  }

  &__form-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 48px;

    @media (width < $media-first) {
      grid-template-columns: 1fr;
      margin-bottom: 24px;
      gap: 8px;
    }

    @media (width < $media-1024) {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-bottom: 32px;
    }

    @media (width < $media-768) {
      grid-template-columns: 1fr;
      margin-bottom: 24px;
      gap: 8px;
    }
  }
}
