.price {
  $self: &;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    & > *:nth-child(n + 7) {
      display: none;
    }
  }

  & &__btn {
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
  }

  & &__toggler {
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;

    &--hide-price {
      display: none;
    }

    &--show-price {
      display: block;
    }
  }

  &--open {
    & #{$self}__toggler {
      &--hide-price {
        display: block;
      }

      &--show-price {
        display: none;
      }
    }

    #{$self}__grid {
      & > * {
        display: flex;
      }
    }
  }
}
