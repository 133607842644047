.example {
  .swiper {
    border-radius: 8px;

    @media (width < $media-768) {
      width: calc(100% + var(--container-gap) + var(--container-gap));
      margin-left: calc(-1 * var(--container-gap));
    }
  }
}
