.price-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $blue-light;
  gap: 16px 20px;

  @media (width < $media-768) {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }

  &__title {
    @include body-big;
  }

  &__value {
    @include body-big-bold;

    flex-shrink: 0;
  }
}
