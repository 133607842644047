.rec {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    grid-auto-rows: 1fr;

    @media (width < $media-first) {
      grid-template-columns: 1fr;
    }
  }
}
#rec-1, #rec-2, #rec-3,
#rec-4, #rec-5, #rec-6 {
  .header__main {
    display: none;
  }
  .header__second {
    display: none;
  }
  .btn--white {
    border: 1px solid black;
    color: black;
  }
}
