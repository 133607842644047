.title {
  @include heading-h2;

  @media (width < $media-768) {
    @include heading-h3;
  }

  &--mb-def {
    margin-bottom: 64px;

    @media (width < $media-first) {
      margin-bottom: 56px;
    }

    @media (width < $media-768) {
      margin-bottom: 32px;
    }
  }

  &--center {
    text-align: center;
  }
}
