.competencies {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .btn {
    margin: 60px auto 0;
  }
  @media (width < $media-768) {
    .competencies__container {
      gap: 8px;
    }
    .btn {
      margin: 30px auto 0;
    }
    .title {
      grid-column: 1/3;
      text-align: center;
    }
  }
}
