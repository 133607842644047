@mixin heading-h1 {
  font-size: 44px;
  font-weight: 800;
  line-height: 58px;
  letter-spacing: unset;
}

@mixin heading-h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: unset;
}

@mixin heading-h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: unset;
}

@mixin body-big-bold {
  font-size: 18px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.01em;
}

@mixin body-big {
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.01em;
}

@mixin body-bold {
  font-size: 14px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.01em;
}

@mixin body {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01em;
}

@mixin body-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.01em;
}
