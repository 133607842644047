.promo {
  background: #E6F1F9;
  height: 368px;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }
  &__box {
    padding: 60px 0;
    max-width: 833px;
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    gap: 32px;
  }
  &__head {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }
  span {
    color: #FF0000;
  }
  &__btns {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  &__link {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #1A69AD;
  }
}
@media screen and (max-width: 1280px) {
  .promo {
    &__img {
      position: absolute;
      z-index: 1;
      right: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .promo {
    &__head {
      font-size: 18px;
      line-height: 24px;
    }
    &__btns {
      flex-direction: column;
      gap: 12px;
    }
    &__box {
      gap: 24px;
    }
    &__img {
      right: -100px;
    }
  }
}
@media screen and (max-width: 450px) {
  .promo {
    &__img {
      right: -240px;
    }
  }
}

