.btn {
  @include body-bold;

  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 13px 24px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  transition:
    $transition-duration color $transition-timing-function,
    $transition-duration background-color $transition-timing-function,
    $transition-duration border $transition-timing-function;

  &--fill {
    color: $white;
    background-color: $blue;
    border-color: $blue;

    &:hover {
      background-color: $blue-dark;
      border-color: $blue-dark;
    }
  }

  &--ghost {
    color: $blue;
    border-color: $blue;

    &:hover {
      color: $white;
      background-color: $blue-dark;
    }
  }
  &--white {
    color: $white;
    border-color: $white;
    &:hover {
      color: $black;
      background-color: $white;
    }

  }

  &--fluid-mob {
    @media (width < $media-768) {
      width: 100%;
    }
  }
}
