.benefit {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media (width < $media-1024) {
      grid-template-columns: 1fr;
    }
    @media (width < $media-768) {
      gap: 12px;
    }
  }

  &__card {
    @include shadow;

    width: 100%;
    padding: 40px;
    border-radius: 8px;
    transition: $transition-duration transform $transition-timing-function;

    &:hover {
      transform: scale(0.95);
    }

    @media (width < $media-768) {
      padding: 14px;
    }
  }

  &__card-title {
    @include heading-h3;

    margin-bottom: 16px;
    color: $blue;

    @media (width < $media-first) {
      @include body-big-bold;
    }
  }

  &__card-text {
    @include body;

    display: block;
    padding-left: 8px;
    color: $black;
    border-left: 1px solid $blue;
  }
}
