.footer-sub {
  padding: 16px 0;

  & &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (width < $media-768) {
      flex-direction: column;
      gap: 12px;
      align-items: unset;
    }
  }

  &__itm {
    @include body;

    a {
      display: inline;
      padding: 4px;
      margin: -4px;
      color: $red;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
