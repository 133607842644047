.modal-body-feedback {
  &__title {
    margin-bottom: 8px;
  }

  &__desc {
    margin-bottom: 24px;
  }

  &__btn {
    margin-top: 32px;
  }

  &__list {
    display: grid;
    grid-template-columns: 100%;
    gap: 8px 0;
  }

  &__doc {
    @include body-small;

    margin-top: 8px;
    color: $gray-dark;

    @media (width < $media-768) {
      br {
        display: none;
      }
    }

    a {
      display: inline;
      text-decoration: underline;
    }
  }
}
