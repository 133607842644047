// Цветовая схема
$white: #fff;
$black: #222;

$gray-dark: #676767;
$gray-light: #e0e0e0;

$blue-black: #002368;
$blue-dark: #00398e;
$blue: #0968ad;
$blue-light: #c9e8ff;

$red: #ff4667;
// ---

// Анимации
$transition-duration: 200ms;
$transition-timing-function: linear;
// ---

// Настройка контейнера в scss для высчитывания media и передачи в css var
$container-width: 1276px;
$container-gap--desktop: 30px;
$container-gap--tablet: 15px;
// ---

// Media запросы
$media-first: #{$container-width + $container-gap--desktop + $container-gap--desktop + 20px};
$media-1024: '1024px';
$media-768: '768px';
$media-930: '930px';
$media-800: '800px';
// ---

// Настройка контейнера в css var из scss var
// stylelint-disable-next-line
:root {
  --container-width: #{$container-width};
  --container-gap: #{$container-gap--desktop};

  @media (width < $media-1024) {
    --container-gap: #{$container-gap--tablet};
  }
}
// ---

// Настройка gap между секций
// stylelint-disable-next-line
:root {
  --section-gap: 120px;

  @media (width < $media-1024) {
    --section-gap: 80px;
  }
}
// ---
