.step {
  &__inner {
    @include shadow;

    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 8px;
    gap: 40px;

    @media (width < $media-768) {
      flex-direction: column-reverse;
      gap: 0;
    }
  }

  &__right {
    width: 614px;
    max-width: 100%;
    padding: 42px 42px 42px 0;
    margin-top: auto;
    margin-bottom: auto;

    @media (width < $media-first) {
      padding: 32px 24px 32px 0;
    }

    @media (width < $media-768) {
      width: 100%;
      padding: 24px 15px;
    }
  }

  &__text {
    @include body-big;

    @media (width < $media-768) {
      @include body;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__title {
    @include heading-h2;

    margin-bottom: 24px;
    color: $blue;

    @media (width < $media-first) {
      @include heading-h3;

      margin-bottom: 16px;
    }
  }

  // &__area {
  //   margin-bottom: 40px;
  // }

  &__banner {
    @media (width < $media-1024) {
      margin-left: -150px;
    }

    @media (width < $media-768) {
      width: 296px;
      max-width: 100%;
      margin-left: -20px;
    }
  }
}
