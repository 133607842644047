.input {
  @include body;

  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid $gray-light;
  border-radius: 8px;
  transition: $transition-duration border $transition-timing-function;

  &::placeholder {
    color: $gray-dark;
  }

  &--error {
    border-color: $red;
  }
}
