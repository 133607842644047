@use 'sass:math';

.preview-picture {
  $width: 628px;
  $height: 617px;

  position: relative;
  width: $width;
  min-width: 0;
  max-width: 100%;

  &::before {
    @include pseudo;
    $pt: math.div($height, $width) * 100%;

    padding-top: calc(#{$pt} + 0px);
  }

  &__panel {
    @include shadow;

    position: absolute;
    z-index: 3;
    width: math.div(256px, $width) * 100%;
    border-radius: 8px;

    &--1 {
      right: 0;
      bottom: math.div(110px, $height) * 100%;
    }

    &--2 {
      right: math.div(156px, $width) * 100%;
      bottom: 0;
    }
  }

  &__main {
    $w: math.div(1014px, 2);
    $w-out: math.div($w, $width) * 100%;

    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: $w-out;

    img {
      width: 100%;
    }
  }

  &__back {
    position: absolute;
    top: math.div(37px, $height) * 100%;
    left: 0;
    z-index: 1;
    width: math.div(428px, $width) * 100%;
  }
}
