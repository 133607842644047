.modal-body {
  @include shadow;

  position: relative;
  width: 549px;
  max-width: 100%;
  padding: 60px 40px 40px 40px;
  margin: auto;
  background-color: $white;
  border-radius: 8px;

  @media (width < $media-768) {
    padding: 60px 15px 20px 15px;
  }

  &__close {
    position: absolute;
    top: 28px;
    right: 28px;
    padding: 4px;
    margin: -4px;
    color: $gray-dark;
    cursor: pointer;
    transition: $transition-duration color $transition-timing-function;

    &:hover {
      color: $red;
    }
  }

  &__close-icon {
    width: 18px;
    height: 18px;
  }

  &__title {
    @include heading-h3;

    @media (width < $media-768) {
      @include body-big-bold;
    }
  }

  &__desc {
    @include body;
  }

  &__btn {
    width: 100%;
  }
}
