.map {
  @media (width < $media-first) {
    height: 400px;
  }

  @media (width < $media-1024) {
    height: 300px;
  }

  @media (width < $media-768) {
    height: 190px;
  }
}
