.slider-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 100%;

  @media (width < $media-768) {
    justify-content: space-between;
    gap: 32px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 48px;
    color: $white;
    cursor: pointer;
    background-color: $blue-light;
    border-radius: 8px;
    transition: $transition-duration background-color $transition-timing-function;

    @media (width < $media-768) {
      width: 58px;
      height: 32px;
    }

    &:hover {
      background-color: $blue-dark;
    }

    svg {
      width: 24px;
      height: 20px;

      @media (width < $media-768) {
        width: 16px;
        height: 12px;
      }
    }
  }

  &__dots {
    display: flex;
    gap: 24px;

    @media (width < $media-768) {
      gap: 8px;
    }

    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      border: 3px solid $blue-light;
      border-radius: 50%;
      transition:
        $transition-duration border $transition-timing-function,
        $transition-duration background $transition-timing-function;

      @media (width < $media-768) {
        width: 10px;
        height: 10px;
        border-width: 2px;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $blue;
      border-color: $blue;
    }
  }

  &--mt-def {
    margin-top: 48px;

    @media (width < $media-768) {
      margin-top: 32px;
    }
  }
}
