.competencies-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-shadow: 1px 2px 20px rgba(0, 32, 115, 0.1);
  border-radius: 8px;
  align-items: flex-start;
  justify-content: space-between;
  height: 160px;
  box-sizing: border-box;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  &__img {
    width: 64px;
    height: 64px;
  }
}
@media (width < $media-768) {
  .competencies-card {
    justify-content: unset;
    gap: 12px;
    padding: 12px;
    height: unset;
    &__title {
      font-size: 14px;
      line-height: 18px;
    }
    &__img {
        width: 32px;
        height: 32px;
    }
  }
}

