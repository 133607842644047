.price-toggler {
  width: max-content;
  color: $blue;
  cursor: pointer;
  transition: $transition-duration color $transition-timing-function;

  &:hover {
    color: $blue-dark;
  }

  &__inner {
    display: flex;
    align-items: center;
    padding: 4px;
    margin: -4px;
    gap: 8px;
  }

  &__title {
    @include body-bold;
  }

  &__icon {
    width: 16px;
    height: 9px;
  }
}
