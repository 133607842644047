.menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 400;
  display: flex;
  flex-direction: column;
  width: 420px;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $white;

  @media (width < $media-1024) {
    width: 100%;
  }

  &__header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 24px var(--container-gap);
    margin-bottom: 40px;

    @media (width < $media-1024) {
      height: 60px;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 20px;
    }
  }

  &__logo {
    width: 150px;
    visibility: hidden;
    @media (width < $media-1024) {
      width: 130px;
    }
  }

  &__close {
    padding: 4px;
    margin: -4px;
    cursor: pointer;
    transition: $transition-duration color $transition-timing-function;

    &:hover {
      color: $red;
    }
  }

  &__close-icon {
    width: 18px;
    height: 18px;

    @media (width < $media-1024) {
      width: 16px;
      height: 16px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 var(--container-gap) 24px var(--container-gap);
  }

  &__list {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 56px;
    gap: 32px 0;

    @media (width < $media-1024) {
      gap: 28px 0;
    }
  }

  &__link {
    @include body-big;

    width: max-content;
    max-width: 100%;
    padding: 4px;
    margin: -4px;
    cursor: pointer;
    transition: $transition-duration color $transition-timing-function;

    &:hover {
      color: $blue;
    }
  }

  & &__btn {
    width: 100%;
    margin-top: auto;
  }
}
