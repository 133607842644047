.preview {
  padding-top: 32px;
  height: 700px;
  position: relative;
  z-index: 2;
  @media (width < $media-768) {
    padding-top: 16px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 20px;

    @media (width < $media-1024) {
      gap: 0 8px;
    }

    @media (width < $media-768) {
      flex-direction: column-reverse;
      gap: 0;
    }
  }
  &__check {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    display: flex;
    gap: 10px;
    align-items:center;
  }
  &-circle {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    &__text {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #1A69AD;
    }
    &__circle {
      cursor: pointer;
      background: #0969AD;
      border-radius: 50%;
      width: 208px;
      height: 208px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: $white;
      gap: 12px;
    }
    &__down {
      width: 24px;
      height: 24px;
    }
  }
  &__title {
    @include heading-h1;
    max-width: 782px;
    margin-bottom: 16px;

    span {
      color: $blue;
    }

    @media (width < $media-1024) {
      @include heading-h2;
      max-width: 450px;
      margin-bottom: 8px;
    }

    @media (width < $media-768) {
      @include heading-h3;

      margin-bottom: 16px;
    }
  }

  &__desc {
    @include body-big;

    margin-bottom: 56px;

    @media (width < $media-1024) {
      @include body;

      margin-bottom: 40px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
  &__link {
    width: 24px;
    height: 24px;
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (width < $media-1024) {
      flex-direction: column;
      gap: 8px 0;
    }

    .btn {
      @media (width < $media-1024) {
        width: 280px;
      }

      @media (width < $media-768) {
        width: 100%;
      }
    }
  }

  &__body {
    flex-shrink: 0;
    width: max-content;

    @media (width < $media-768) {
      width: 100%;
      padding-top: 20px;
    }
  }
}
@media (width < $media-930) {
  .preview {
    display: flex;
    flex-direction: column-reverse;
    height: unset;
    margin-top: 80%;
    &__footer {
      .btn {
        width: unset;
      }
    }
    &__title {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
    }
    &-circle {
      position: relative;
      &__circle {
        width: 140px;
        height: 140px;
        gap: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
