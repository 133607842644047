.card-faq {
  @include shadow;

  --gap-x: 40px;

  color: $black;
  user-select: none;
  background-color: $white;
  border-radius: 10px;
  transition:
    $transition-duration color $transition-timing-function,
    $transition-duration background $transition-timing-function;

  @media (width < $media-first) {
    --gap-x: 20px;
  }

  @media (width < $media-768) {
    border-radius: 20px;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px var(--gap-x);
    cursor: pointer;
    transition: $transition-duration padding $transition-timing-function;

    @media (width < $media-first) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &--open {
      padding-bottom: 20px;

      @media (width < $media-first) {
        padding-bottom: 10px;
      }

      @media (width < $media-768) {
        padding-bottom: 20px;
      }
    }
  }

  &__title {
    @include heading-h3;

    @media (width < $media-1024) {
      @include body-big-bold;
    }

    @media (width < $media-768) {
      @include body-bold;
    }
  }

  &__plus {
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-left: 20px;
    transition: $transition-duration transform $transition-timing-function;

    @media (width < $media-first) {
      width: 18px;
      height: 18px;
    }

    &--open {
      transform: rotate(45deg);
    }
  }

  &__body {
    @include body-big;

    box-sizing: content-box;
    height: 0;
    padding: 0 var(--gap-x) 0 var(--gap-x);
    overflow: hidden;
    opacity: 0;
    transition:
      $transition-duration opacity $transition-timing-function,
      $transition-duration height $transition-timing-function,
      $transition-duration padding $transition-timing-function;

    @media (width < $media-1024) {
      @include body;
    }

    &--open {
      padding-bottom: 40px;
      opacity: 1;
      will-change: height, padding;

      @media (width < $media-first) {
        padding-bottom: 20px;
      }
    }
  }

  &--open {
    color: $white;
    background-color: $blue;
  }
}
