#consultation, #diagnostika {
  .header__main {
    display: none;
  }
  .header__second {
    z-index: 0;
  }
  .btn--white {
    border: 1px solid black;
    color: black;
  }
  .smart-body-article {
    padding-bottom: 40px;
    position: relative;
    z-index: 1;

    &__title {
      text-align: left;
      color: $black;
      max-width: 352px;
      margin-bottom: 24px;
    }
    &__area {
      max-width: 632px;
    }
  }
  .map {
    margin-top: 300px;
  }
  .btn {
    position: relative;
    z-index: 1;
  }
}
@media (width < $media-768) {
  #consultation, #diagnostika {
    .header__phone {
      color: $black;
    }
  }
}
@media (width < 1300px) {
  #consultation, #diagnostika {
    .header__burger {
      display: none;
    }
    .header__burger--second {
      display: block;
    }
    .header__link-mobile {
      display: none;
    }
    .header__link {
      display: block;
    }
    .map {
      margin-top: 150px;
    }
    .smart-body-article {
      &__title {
        font-weight: 800;
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}
