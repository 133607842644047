.smart-body-article {
  padding-top: 80px;
  padding-bottom: 120px;


  @media (width < $media-1024) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__sub {
    @include body-bold;

    margin-bottom: 4px;
    color: $gray-dark;
    text-align: center;
    text-transform: uppercase;
  }

  &__title {
    @include heading-h1;

    margin-bottom: 80px;
    color: $blue;
    text-align: center;

    @media (width < $media-1024) {
      @include heading-h3;

      margin-bottom: 40px;
    }
  }

  &__area {
    strong,
    b {
      font-weight: bold;
    }

    h2 {
      @include heading-h2;

      @media (width < $media-1024) {
        @include heading-h3;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &:not(:first-child) {
        margin-top: 42px;
      }
    }

    p {
      @include body-big;

      @media (width < $media-1024) {
        @include body;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    h3 {
      @include heading-h3;

      @media (width < $media-1024) {
        @include body-big-bold;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    ol {
      list-style-type: disc;
    }

    ul {
      list-style-type: decimal;
    }

    ol,
    ul {
      padding-left: 60px;

      @media (width < $media-1024) {
        padding-left: 44px;
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &:not(:first-child) {
        margin-top: 24px;
      }

      li {
        @include body-big;

        @media (width < $media-1024) {
          @include body;
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &::marker {
          @include heading-h3;

          color: $blue;
        }
      }
    }
  }

  & &__file {
    margin-top: 40px;
  }
}
